import { useCallback } from 'react';

export function useGeneratePayloadAndDataList({
    formFields,
    tablePreviewData
}) {
    const result = useCallback(() => {
        if (
            formFields.length < 0 ||
            (formFields.length === 1 && !formFields[0].element.code)
        )
            return [];
        const dataFiltered = formFields.filter((item) => item.element.code);
        const newListMapped = dataFiltered.map((item) => {
            if (item.element.code && item.estRempliAutomatique) {
                return {
                    element: item.element,
                    typeMoyenPaiement: item.typeMoyenPaiement
                };
            }
            if (item.element.code && !item.estRempliAutomatique) {
                const newelement = {
                    element: item.element,
                    typeMoyenPaiement: item.typeMoyenPaiement,
                    details: []
                };
                if (item.typeMoyenPaiement === 'Cash') {
                    newelement.idCaisse = item.idCaisse;
                }
                if (item.estQuantite) {
                    newelement.prix = item.prix;
                }
                if (item?.details?.length) {
                    const elementDetails = item.details.map((detail) => {
                        const newDetail = {
                            secteur: { code: detail.secteur.code },
                            periodes: []
                        };
                        if (detail?.periodes?.length) {
                            const DetailPeriodes = detail.periodes.map(
                                (periode) => {
                                    const newPeriodes = {
                                        dateDebut: periode.dateDebut,
                                        dateFin: periode.dateFin,
                                        datePaiement: periode.datePaiement,
                                        mois: periode.mois,
                                        beneficiaires: []
                                    };
                                    if (periode.beneficiaires.length) {
                                        const beneficiaireArray =
                                            periode.beneficiaires.map(
                                                (beneficiaire) => {
                                                    const newBenficiaire = {
                                                        typeEntite:
                                                            beneficiaire.typeEntite,
                                                        valeurTypeEntite:
                                                            beneficiaire.valeurTypeEntite
                                                    };
                                                    if (item.estQuantite) {
                                                        newBenficiaire.quantite =
                                                            beneficiaire.quantite;
                                                    }
                                                    if (!item.estQuantite) {
                                                        newBenficiaire.montant =
                                                            beneficiaire.montant;
                                                    }
                                                    return newBenficiaire;
                                                }
                                            );
                                        newPeriodes.beneficiaires =
                                            beneficiaireArray;
                                    }
                                    return newPeriodes;
                                }
                            );
                            newDetail.periodes = DetailPeriodes;
                        }
                        return newDetail;
                    });
                    newelement.details = elementDetails;
                }
                return {
                    ...newelement
                };
            }
            return {};
        });
        return {
            payloadList: newListMapped || [],
            tableList: tablePreviewData({ list: dataFiltered }) || []
        };
    }, [formFields, tablePreviewData]);
    return result();
}
