import React, { Fragment } from 'react';
import { IoMdArrowDropdown, IoMdArrowDropright } from 'react-icons/io';
import { MdOutlineTableView } from 'react-icons/md';
import { BsDot } from 'react-icons/bs';
import { FORM_CONST } from '@napp-inc/jnapp-util';
import { TiDeleteOutline } from 'react-icons/ti';
import {
    ConditionalRenderingWrapper,
    CustomConfirmButton
} from '../../../../../../components';

export function LeftSidePannel({
    onShow,
    formFields,
    toggledVisibilityView,
    toggledView,
    handleSubmit,
    form,
    containerClassName = 'col-3 my-3 me-2',
    pannelLabel = 'Liste des charges',
    setFormFields,
    draftComponentAction,
    handleRemove
}) {
    return (
        <div className={containerClassName}>
            <div className="mt-1 alert alert-secondary d-flex justify-content-between">
                <span>{pannelLabel}</span>
                <MdOutlineTableView
                    className="fs-4"
                    title="Prévisualiser"
                    role="button"
                    onClick={onShow}
                />
            </div>
            {formFields.filter(
                (item) => !item.isTooggled && !!item.element.code
            ).length ? (
                <>
                    <div
                    // style={{ border: '1px dashed gray' }}
                    // className="alert alert-info"
                    >
                        <div className="">
                            {formFields.map((item, index) => (
                                <Fragment key={item.element.designation}>
                                    {!item.isTooggled && !!item.element.code ? (
                                        <ul
                                            style={{
                                                listStyleType: 'none',
                                                margin: 0,
                                                padding: 0
                                            }}
                                        >
                                            <li>
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <div>
                                                        {item.isVisible ? (
                                                            <IoMdArrowDropdown
                                                                role="button"
                                                                onClick={() =>
                                                                    toggledVisibilityView(
                                                                        {
                                                                            code: item
                                                                                .element
                                                                                .code,
                                                                            formFields,
                                                                            setFormFields
                                                                        }
                                                                    )
                                                                }
                                                            />
                                                        ) : (
                                                            <IoMdArrowDropright
                                                                role="button"
                                                                onClick={() =>
                                                                    toggledVisibilityView(
                                                                        {
                                                                            code: item
                                                                                .element
                                                                                .code,
                                                                            formFields,
                                                                            setFormFields
                                                                        }
                                                                    )
                                                                }
                                                            />
                                                        )}
                                                        {/* <IoMdArrowDropright /> */}
                                                        <span
                                                            role="button"
                                                            tabIndex={0}
                                                            onClick={() =>
                                                                toggledView({
                                                                    code: item
                                                                        ?.element
                                                                        ?.code,
                                                                    isTargeted: true,
                                                                    formFields,
                                                                    setFormFields
                                                                })
                                                            }
                                                            onKeyDown={() =>
                                                                toggledView({
                                                                    code: item
                                                                        ?.element
                                                                        ?.code,
                                                                    isTargeted: true,
                                                                    formFields,
                                                                    setFormFields
                                                                })
                                                            }
                                                            className="ms-2"
                                                        >
                                                            {
                                                                item?.element
                                                                    ?.designation
                                                            }
                                                        </span>
                                                    </div>
                                                    <TiDeleteOutline
                                                        role="button"
                                                        title="Supprimer la charge ainsi que toutes les informations la concernant!"
                                                        className="text-secondary fs-6"
                                                        onClick={() =>
                                                            handleRemove({
                                                                index,
                                                                formFields,
                                                                setFormFields
                                                            })
                                                        }
                                                    />
                                                </div>
                                                {item.details.length &&
                                                item.isVisible
                                                    ? item.details.map(
                                                          (
                                                              detail,
                                                              indexAtSecondLevel
                                                          ) => (
                                                              <ConditionalRenderingWrapper
                                                                  key={`${item.element.designation}${detail.direction}${detail.secteur.designation}${detail.periodes}`}
                                                                  isShouldBeRendered={
                                                                      !!detail
                                                                          .secteur
                                                                          .designation
                                                                  }
                                                              >
                                                                  <ul
                                                                      style={{
                                                                          marginLeft:
                                                                              '18px',
                                                                          listStyleType:
                                                                              'none',
                                                                          //   margin: 0,
                                                                          padding: 0
                                                                      }}
                                                                  >
                                                                      <li>
                                                                          {detail.isVisible ? (
                                                                              <IoMdArrowDropdown
                                                                                  role="button"
                                                                                  onClick={() =>
                                                                                      toggledVisibilityView(
                                                                                          {
                                                                                              code: item
                                                                                                  .element
                                                                                                  .code,
                                                                                              index,
                                                                                              indexAtSecondLevel,
                                                                                              formFields,
                                                                                              setFormFields
                                                                                          }
                                                                                      )
                                                                                  }
                                                                              />
                                                                          ) : (
                                                                              <IoMdArrowDropright
                                                                                  role="button"
                                                                                  onClick={() =>
                                                                                      toggledVisibilityView(
                                                                                          {
                                                                                              code: item
                                                                                                  .element
                                                                                                  .code,
                                                                                              index,
                                                                                              indexAtSecondLevel,
                                                                                              formFields,
                                                                                              setFormFields
                                                                                          }
                                                                                      )
                                                                                  }
                                                                              />
                                                                          )}
                                                                          <span
                                                                              role="button"
                                                                              tabIndex={
                                                                                  0
                                                                              }
                                                                              onClick={() =>
                                                                                  toggledView(
                                                                                      {
                                                                                          code: item
                                                                                              ?.element
                                                                                              ?.code,
                                                                                          index,
                                                                                          indexAtSecondLevel,
                                                                                          isTargeted: true,
                                                                                          formFields,
                                                                                          setFormFields
                                                                                      }
                                                                                  )
                                                                              }
                                                                              onKeyDown={() =>
                                                                                  toggledView(
                                                                                      {
                                                                                          code: item
                                                                                              ?.element
                                                                                              ?.code,
                                                                                          index,
                                                                                          indexAtSecondLevel,
                                                                                          isTargeted: true,
                                                                                          formFields,
                                                                                          setFormFields
                                                                                      }
                                                                                  )
                                                                              }
                                                                              className="ms-2"
                                                                          >
                                                                              {
                                                                                  detail
                                                                                      .secteur
                                                                                      .designation
                                                                              }
                                                                          </span>
                                                                          {detail
                                                                              .periodes
                                                                              .length &&
                                                                          detail.isVisible
                                                                              ? detail.periodes.map(
                                                                                    (
                                                                                        periode,
                                                                                        indexAtThirdLevel
                                                                                    ) => (
                                                                                        <ConditionalRenderingWrapper
                                                                                            key={`${item.element.code}${detail.direction}${detail.secteur.code}${periode.mois}`}
                                                                                            isShouldBeRendered={
                                                                                                periode.mois &&
                                                                                                periode.datePaiement
                                                                                            }
                                                                                        >
                                                                                            <ul
                                                                                                style={{
                                                                                                    marginLeft:
                                                                                                        '18px',
                                                                                                    listStyleType:
                                                                                                        'none',
                                                                                                    //   margin: 0,
                                                                                                    padding: 0
                                                                                                }}
                                                                                            >
                                                                                                <li>
                                                                                                    {periode.isVisible ? (
                                                                                                        <IoMdArrowDropdown
                                                                                                            role="button"
                                                                                                            onClick={() =>
                                                                                                                toggledVisibilityView(
                                                                                                                    {
                                                                                                                        code: item
                                                                                                                            .element
                                                                                                                            .code,
                                                                                                                        index,
                                                                                                                        indexAtSecondLevel,
                                                                                                                        indexAtThirdLevel,
                                                                                                                        formFields,
                                                                                                                        setFormFields
                                                                                                                    }
                                                                                                                )
                                                                                                            }
                                                                                                        />
                                                                                                    ) : (
                                                                                                        <IoMdArrowDropright
                                                                                                            role="button"
                                                                                                            onClick={() =>
                                                                                                                toggledVisibilityView(
                                                                                                                    {
                                                                                                                        code: item
                                                                                                                            .element
                                                                                                                            .code,
                                                                                                                        index,
                                                                                                                        indexAtSecondLevel,
                                                                                                                        indexAtThirdLevel,
                                                                                                                        formFields,
                                                                                                                        setFormFields
                                                                                                                    }
                                                                                                                )
                                                                                                            }
                                                                                                        />
                                                                                                    )}
                                                                                                    <span
                                                                                                        role="button"
                                                                                                        tabIndex={
                                                                                                            0
                                                                                                        }
                                                                                                        onClick={() =>
                                                                                                            toggledView(
                                                                                                                {
                                                                                                                    code: item
                                                                                                                        ?.element
                                                                                                                        ?.code,
                                                                                                                    index,
                                                                                                                    indexAtSecondLevel,
                                                                                                                    indexAtThirdLevel,
                                                                                                                    isTargeted: true,
                                                                                                                    formFields,
                                                                                                                    setFormFields
                                                                                                                }
                                                                                                            )
                                                                                                        }
                                                                                                        onKeyDown={() =>
                                                                                                            toggledView(
                                                                                                                {
                                                                                                                    code: item
                                                                                                                        ?.element
                                                                                                                        ?.code,
                                                                                                                    index,
                                                                                                                    indexAtSecondLevel,
                                                                                                                    indexAtThirdLevel,
                                                                                                                    isTargeted: true,
                                                                                                                    formFields,
                                                                                                                    setFormFields
                                                                                                                }
                                                                                                            )
                                                                                                        }
                                                                                                        className="ms-2"
                                                                                                    >
                                                                                                        {
                                                                                                            periode.mois
                                                                                                        }
                                                                                                    </span>
                                                                                                    {periode
                                                                                                        .beneficiaires
                                                                                                        .length &&
                                                                                                    periode.isVisible
                                                                                                        ? periode.beneficiaires.map(
                                                                                                              (
                                                                                                                  beneficiaire
                                                                                                              ) => (
                                                                                                                  <ConditionalRenderingWrapper
                                                                                                                      key={`${item.element.code}${detail.direction}${detail.secteur.code}${periode.mois}${beneficiaire.typeEntite}${beneficiaire.valeurTypeEntite.designation}`}
                                                                                                                      isShouldBeRendered={
                                                                                                                          !!beneficiaire
                                                                                                                              .valeurTypeEntite
                                                                                                                              .designation
                                                                                                                      }
                                                                                                                  >
                                                                                                                      <ul
                                                                                                                          style={{
                                                                                                                              //   marginLeft:
                                                                                                                              //       '21px',
                                                                                                                              listStyleType:
                                                                                                                                  'none'
                                                                                                                              //   margin: 0,
                                                                                                                              //   padding: 0
                                                                                                                          }}
                                                                                                                      >
                                                                                                                          <li>
                                                                                                                              <span>
                                                                                                                                  <BsDot />
                                                                                                                                  {
                                                                                                                                      beneficiaire
                                                                                                                                          .valeurTypeEntite
                                                                                                                                          .designation
                                                                                                                                  }
                                                                                                                                  <span className="ms-2 badge bg-warning text-dark">
                                                                                                                                      {item.estQuantite
                                                                                                                                          ? beneficiaire.quantite
                                                                                                                                          : beneficiaire.montant}
                                                                                                                                  </span>
                                                                                                                              </span>
                                                                                                                          </li>
                                                                                                                      </ul>
                                                                                                                  </ConditionalRenderingWrapper>
                                                                                                              )
                                                                                                          )
                                                                                                        : null}
                                                                                                </li>
                                                                                            </ul>
                                                                                        </ConditionalRenderingWrapper>
                                                                                    )
                                                                                )
                                                                              : null}
                                                                      </li>
                                                                  </ul>
                                                              </ConditionalRenderingWrapper>
                                                          )
                                                      )
                                                    : null}
                                            </li>
                                        </ul>
                                    ) : null}
                                </Fragment>
                            ))}
                        </div>
                    </div>
                    <CustomConfirmButton
                        modalText="Voulez-vous soumettre cette élaboration?"
                        modalChildren={draftComponentAction}
                        type="button"
                        backdrop="static"
                        text="Soumettre elaboration"
                        className="btn-success btn mt-5 w-100"
                        confirmOnClick={handleSubmit}
                        disabled={form.status === FORM_CONST.CREATION_EN_COURS}
                        isActionEncours={
                            form.status === FORM_CONST.CREATION_EN_COURS
                        }
                    />
                </>
            ) : (
                <div className="text-center">
                    <span>Liste vide...</span>
                </div>
            )}
        </div>
    );
}
