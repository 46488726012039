import React from 'react';
import { AiFillMinusCircle } from 'react-icons/ai';
import {
    IoIosAddCircleOutline,
    IoIosArrowDropdown,
    IoIosArrowDropup
} from 'react-icons/io';
import {
    CustomLiteDynamicDateInput,
    CustomLiteDynamicDropdown
} from '../../../../../../components';

export function PeriodesRowSelector({
    index,
    detailIndex,
    periodeIndex,
    periodeItem,
    handleSelectChange,
    periodes,
    handleChange,
    onToggleView,
    handleRemove,
    handleAddHighLevel,
    formFields,
    setFormFields
}) {
    return (
        <div className="d-flex flex-row align-items-center justify-content-center">
            <div
                style={{
                    height: '46px',
                    width: '16px'
                }}
            >
                <p />
            </div>
            {/* Periode */}
            <CustomLiteDynamicDropdown
                containerClassName="col-5 me-3"
                ariaLabel="Periode Selector"
                name="dateDebut|dateFin"
                customproperty="periodes"
                value={periodeItem?.dateDebut}
                options={periodes}
                hiddenOption="Selectionnez un période"
                onChange={handleSelectChange}
                handleProps={{
                    index,
                    indexAtSecondLevel: detailIndex,
                    indexAtThirdLevel: periodeIndex,
                    setFormFields,
                    formFields,
                    periodes
                }}
                labelStyle={{
                    fontSize: '13px',
                    paddingBottom: '8px',
                    lineHeight: '20px',
                    minHeight: '20px',
                    display: 'block',
                    color: 'rgba(0,0,0,0.5)'
                }}
                label="Période"
                selectClassName="form-select form-select-sm col-3"
            />
            {/* Date Paiement */}
            <CustomLiteDynamicDateInput
                containerClassName="col-3 me-3"
                labelStyle={{
                    fontSize: '13px',
                    paddingBottom: '8px',
                    lineHeight: '20px',
                    minHeight: '20px',
                    display: 'block',
                    color: 'rgba(0,0,0,0.5)'
                }}
                label="Date de paiement"
                inputClassName="form-control form-control-sm col-3"
                ariaLabel="date paiement"
                name="datePaiementString"
                customproperty="datePaiement"
                value={periodeItem.datePaiementString}
                onChange={handleChange}
                inputProps={{
                    index,
                    indexAtSecondLevel: detailIndex,
                    indexAtThirdLevel: periodeIndex,
                    setFormFields,
                    formFields
                }}
            />
            <div className="col-auto pt-2 me-3">
                <AiFillMinusCircle
                    role="button"
                    title="Supprimer Informations de la Période"
                    className="text-secondary fs-4"
                    onClick={() =>
                        handleRemove({
                            index,
                            indexAtSecondLevel: detailIndex,
                            indexAtThirdLevel: periodeIndex,
                            formFields,
                            setFormFields
                        })
                    }
                />
            </div>
            <div className="col-auto me-3 pt-2">
                <IoIosAddCircleOutline
                    role="button"
                    title="Ajouter Période"
                    className="text-primary fs-4"
                    onClick={() =>
                        handleAddHighLevel({
                            type: 'Periodes',
                            ancestorId: index,
                            indexAtSecondLevel: detailIndex,
                            formFields,
                            setFormFields
                        })
                    }
                />
            </div>
            <div className="col-auto pt-2">
                {formFields[index].details[detailIndex].periodes[periodeIndex]
                    .isTooggled ? (
                    <IoIosArrowDropup
                        className="fs-4"
                        title="Regrouper les détails"
                        role="button"
                        onClick={() =>
                            onToggleView({
                                code: formFields[index].details[detailIndex]
                                    .periodes[periodeIndex].mois,
                                index,
                                indexAtSecondLevel: detailIndex,
                                indexAtThirdLevel: periodeIndex,
                                setFormFields,
                                formFields
                            })
                        }
                    />
                ) : (
                    <IoIosArrowDropdown
                        className="fs-4"
                        title="Afficher les détails"
                        role="button"
                        onClick={() =>
                            onToggleView({
                                code: formFields[index].details[detailIndex]
                                    .periodes[periodeIndex].mois,
                                index,
                                indexAtSecondLevel: detailIndex,
                                indexAtThirdLevel: periodeIndex,
                                setFormFields,
                                formFields
                            })
                        }
                    />
                )}
            </div>
        </div>
    );
}
