/* eslint-disable react/no-array-index-key */
import React from 'react';
import { MOYEN_PAIEMENT } from '@napp-inc/jnapp-util';
import {
    BeneficiaireRowSelector,
    ChargeFixeRowSelector,
    PeriodesRowSelector,
    SecteurRowSelector
} from './organisme';

// formFields,
//     setFormFields
export function ChargeFixeBaseForm({
    formFields,
    setFormFields,
    field,
    index,
    handleAddHighLevel,
    handleSelectChange,
    charges,
    caisses,
    handleChange,
    handleRemove,
    directions,
    secteurs,
    periodes,
    getTypeBeneficiaire,
    onToggleView
}) {
    const getClassNames = ({ moyenPaiement, estQuantite }) => {
        // second case
        if (moyenPaiement !== MOYEN_PAIEMENT.CASH && estQuantite)
            return {
                chargeClass: 'col-4',
                modePaiementClass: 'col-4',
                caisseClass: '',
                prixClass: 'col-3'
            };
        // Third case
        if (moyenPaiement === MOYEN_PAIEMENT.CASH && !estQuantite)
            return {
                chargeClass: 'col-4',
                modePaiementClass: 'col-4',
                caisseClass: 'col-3',
                prixClass: ''
            };
        // Fourth case
        if (moyenPaiement === MOYEN_PAIEMENT.CASH && estQuantite)
            return {
                chargeClass: 'col-3',
                modePaiementClass: 'col-3',
                caisseClass: 'col-3',
                prixClass: 'col-2'
            };

        // first case
        return {
            chargeClass: 'col-6',
            modePaiementClass: 'col-5',
            caisseClass: '',
            prixClass: ''
        };
    };
    return (
        <div key={index} className="my-3">
            <ChargeFixeRowSelector
                formFields={formFields}
                setFormFields={setFormFields}
                handleRemove={handleRemove}
                getClassNames={getClassNames}
                field={field}
                charges={charges}
                handleSelectChange={handleSelectChange}
                caisses={caisses}
                index={index}
                handleChange={handleChange}
            />
            {!charges.find((charge) => charge.code === field.element.code)
                ?.estRempliAutomatique ? (
                <div className="d-flex flex-column ms-3 border-start">
                    {formFields[index].details.map((detail, detailIndex) => (
                        <div
                            key={detailIndex}
                            className=""
                            id={`${field.element.code}${index}${detailIndex}`}
                        >
                            <SecteurRowSelector
                                setFormFields={setFormFields}
                                handleRemove={handleRemove}
                                handleAddHighLevel={handleAddHighLevel}
                                index={index}
                                detailIndex={detailIndex}
                                formFields={formFields}
                                onToggleView={onToggleView}
                                handleSelectChange={handleSelectChange}
                                directions={directions}
                                detail={detail}
                                charges={charges}
                                field={field}
                                secteurs={secteurs}
                            />
                            {formFields[index].details[detailIndex].isTooggled
                                ? formFields[index].details[
                                      detailIndex
                                  ].periodes.map(
                                      (periodeItem, periodeIndex) => (
                                          <div
                                              key={periodeIndex}
                                              className=""
                                              style={{
                                                  marginLeft: '10px'
                                              }}
                                              id={`${field.element.code}${index}${detailIndex}${periodeIndex}`}
                                          >
                                              <PeriodesRowSelector
                                                  formFields={formFields}
                                                  setFormFields={setFormFields}
                                                  index={index}
                                                  detailIndex={detailIndex}
                                                  periodeIndex={periodeIndex}
                                                  periodeItem={periodeItem}
                                                  handleSelectChange={
                                                      handleSelectChange
                                                  }
                                                  periodes={periodes}
                                                  handleChange={handleChange}
                                                  onToggleView={onToggleView}
                                                  handleRemove={handleRemove}
                                                  handleAddHighLevel={
                                                      handleAddHighLevel
                                                  }
                                              />
                                              {formFields[index].details[
                                                  detailIndex
                                              ].periodes[periodeIndex]
                                                  .isTooggled
                                                  ? formFields[index].details[
                                                        detailIndex
                                                    ].periodes[
                                                        periodeIndex
                                                    ].beneficiaires.map(
                                                        (
                                                            beneficiaireItem,
                                                            beneficiaireIndex
                                                        ) => (
                                                            // eslint-disable-next-line react/no-array-index-key
                                                            <BeneficiaireRowSelector
                                                                key={
                                                                    beneficiaireIndex
                                                                }
                                                                id={`${field.element.code}${index}${detailIndex}${periodeIndex}${beneficiaireItem.valeurTypeEntite.reference}`}
                                                                index={index}
                                                                detailIndex={
                                                                    detailIndex
                                                                }
                                                                periodeIndex={
                                                                    periodeIndex
                                                                }
                                                                beneficiaireIndex={
                                                                    beneficiaireIndex
                                                                }
                                                                handleChange={
                                                                    handleChange
                                                                }
                                                                handleSelectChange={
                                                                    handleSelectChange
                                                                }
                                                                handleRemove={
                                                                    handleRemove
                                                                }
                                                                handleAddHighLevel={
                                                                    handleAddHighLevel
                                                                }
                                                                beneficiaireItem={
                                                                    beneficiaireItem
                                                                }
                                                                beneficiaresOptions={getTypeBeneficiaire(
                                                                    {
                                                                        id: formFields[
                                                                            index
                                                                        ]
                                                                            ?.details[
                                                                            detailIndex
                                                                        ]
                                                                            ?.periodes[
                                                                            periodeIndex
                                                                        ]
                                                                            ?.beneficiaires[
                                                                            beneficiaireIndex
                                                                        ]
                                                                            ?.typeEntite
                                                                    }
                                                                )}
                                                                charges={
                                                                    charges
                                                                }
                                                                formFields={
                                                                    formFields
                                                                }
                                                                setFormFields={
                                                                    setFormFields
                                                                }
                                                            />
                                                        )
                                                    )
                                                  : null}
                                          </div>
                                      )
                                  )
                                : null}
                        </div>
                    ))}
                </div>
            ) : null}
        </div>
    );
}
