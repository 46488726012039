export const mapper = [
    {
        liClass: 'list-group-item col-3',
        divClass: 'd-flex w-100 justify-content-between',
        hClass: 'mb-1 fw-bold',
        hTitle: 'MARCHAND',
        pClass: 'mb-1',
        spanClass: '',
        path: 'marchand.designation'
    },
    {
        liClass: 'list-group-item col-2',
        divClass: 'd-flex w-100 justify-content-between',
        hClass: 'mb-1 fw-bold',
        hTitle: 'ETAT',
        pClass: 'mb-1',
        spanClass: 'badge bg-primary',
        path: 'etat'
    },
    {
        liClass: 'list-group-item col-2',
        divClass: 'd-flex w-100 justify-content-between',
        hClass: 'mb-1 fw-bold',
        hTitle: 'REFERENCE',
        pClass: 'mb-1',
        path: 'numeroReference'
    },
    {
        liClass: 'list-group-item col-2',
        divClass: 'd-flex w-100 justify-content-between',
        hClass: 'mb-1 fw-bold',
        hTitle: 'MONTANT',
        pClass: 'mb-1',
        spanClass: '',
        path: 'montant',
        smallPath: 'devise'
    },
    {
        liClass: 'list-group-item col-2',
        divClass: 'd-flex w-100 justify-content-between',
        hClass: 'mb-1 fw-bold',
        hTitle: 'CLIENT',
        pClass: 'mb-1',
        spanClass: '',
        path: 'client.numero'
    }
];
