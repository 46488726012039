// For Dropdown

import { getNames } from './get-names';

/**
 * @description: Logique handleChange pour les select input
 * @param {Object} param0 : Tout le paramètre
 * @param {Number} param0.index : Index de l'element au plus haut niveau de l'arborescence, voir le state
 * @param {Event} param0.event : Event de l'object input de HTML
 * @param {Number} param0.indexAtSecondLevel : Index de l'element au second niveau de l'arborescence, voir le state
 * @param {Number} param0.indexAtThirdLevel : Index de l'element au troisième niveau de l'arborescence, voir le state
 * @param {Number} param0.id : Index de l'element au quatrième niveau de l'arborescence, voir le state
 */
export const handleSelectChange = ({
    index,
    event,
    indexAtSecondLevel,
    indexAtThirdLevel,
    id,
    formFields,
    charges,
    periodes,
    setFormFields
}) => {
    const values = [...formFields];
    const names = getNames({ name: event.target.name });
    /**
     * @desc: A ce stade, nous traitons toutes les valeurs
     * au premier niveau de l'arborescence (Voir le state)
     */
    if (
        typeof index === 'number' &&
        event &&
        typeof indexAtSecondLevel !== 'number' &&
        typeof indexAtThirdLevel !== 'number' &&
        typeof id !== 'number'
    ) {
        /** Cette condition permet de savoir si la charge est de type remplie automatiquement
         * dans le select input nous avons rajouter une propriété customtypecharge pour le test fait ici
         */
        if (
            event.target.attributes?.customtypecharge?.nodeValue ===
            'estRempliAutomatique'
        ) {
            const findedElement = charges.find(
                (element) => element.code === event.target.value
            );
            values[index][event.target.attributes.customtypecharge.nodeValue] =
                !!findedElement?.[
                    event.target.attributes.customtypecharge.nodeValue
                ];
        }
        /** Cette condition permet de savoir si la charge est de type estQuantite
         * dans le select input nous avons rajouter une propriété customproperty pour le test fait ici
         */
        if (
            event.target.attributes?.customproperty?.nodeValue === 'estQuantite'
        ) {
            const findedElement = charges.find(
                (element) => element.code === event.target.value
            );
            values[index][event.target.attributes.customproperty.nodeValue] =
                !!findedElement?.[
                    event.target.attributes.customproperty.nodeValue
                ];
        }
        /**
         * Pour le dropdown moyen paiement, On vérifie si c'est différent
         * de cash pour vider la propriété idCaisse et caisseDesignation
         */
        if (
            event.target.attributes?.customproperty?.nodeValue ===
            'estMoyenPaiement'
        ) {
            const targetedPropsIdToChange = 'idCaisse';
            const targetedPropsNameToChange = 'caisseDesignation';
            values[index][targetedPropsIdToChange] =
                event.target.value !== 'Cash'
                    ? ''
                    : values[index][targetedPropsIdToChange];
            values[index][targetedPropsNameToChange] =
                event.target.value !== 'Cash'
                    ? ''
                    : values[index][targetedPropsNameToChange];
        }
        /**
         * @desc: serie de tests, pour traiter les select input avec des name de type
         * (property),
         * (property|property),
         * (object.property),
         * (object.property1|object.property2)
         */
        if (names.length > 1 && event.target.name.includes('|')) {
            const [f, s] = names;
            if (f.includes('.')) {
                const [newFf, newFS] = getNames({ name: f });
                values[index][newFf][newFS] = event.target.value;
            }
            if (s.includes('.')) {
                const [newSf, newSs] = getNames({ name: s });
                values[index][newSf][newSs] =
                    typeof event.target.value === 'string'
                        ? event.target.selectedOptions['0'].innerText
                        : event.target.value + 9999;
            }
            if (!f.includes('.') && !s.includes('.')) {
                values[index][f] = event.target.value;
                values[index][s] =
                    typeof event.target.value === 'string'
                        ? event.target.selectedOptions['0'].innerText
                        : event.target.value + 9999;
            }
        } else if (names.length > 1 && event.target.name.includes('.')) {
            const [f, s] = names;
            values[index][f][s] = event.target.value;
        } else {
            values[index][event.target.name] = event.target.value;
        }
    }
    /**
     * @desc: A ce stade, nous traitons toutes les valeurs
     * au deuxième niveau de l'arborescence (Voir le state)
     */
    if (
        typeof index === 'number' &&
        event &&
        typeof indexAtSecondLevel === 'number' &&
        typeof indexAtThirdLevel !== 'number' &&
        typeof id !== 'number'
    ) {
        if (names.length > 1 && event.target.name.includes('|')) {
            const [f, s] = names;
            if (f.includes('.')) {
                const [newFf, newFS] = getNames({ name: f });
                values[index].details[indexAtSecondLevel][newFf][newFS] =
                    event.target.value;
            }
            if (s.includes('.')) {
                const [newSf, newSs] = getNames({ name: s });
                values[index].details[indexAtSecondLevel][newSf][newSs] =
                    typeof event.target.value === 'string'
                        ? event.target.selectedOptions['0'].innerText
                        : event.target.value + 9999;
            }
            if (!f.includes('.') && !s.includes('.')) {
                values[index].details[indexAtSecondLevel][f] =
                    event.target.value;
                values[index].details[indexAtSecondLevel][s] =
                    typeof event.target.value === 'string'
                        ? event.target.selectedOptions['0'].innerText
                        : event.target.value + 9999;
            }
        } else if (names.length > 1 && event.target.name.includes('.')) {
            const [f, s] = names;
            values[index].details[indexAtSecondLevel][f][s] =
                event.target.value;
        } else {
            values[index].details[indexAtSecondLevel][event.target.name] =
                event.target.value;
        }
    }
    /**
     * @desc: A ce stade, nous traitons toutes les valeurs
     * au troisième niveau de l'arborescence (Voir le state)
     */
    if (
        typeof index === 'number' &&
        event &&
        typeof indexAtSecondLevel === 'number' &&
        typeof indexAtThirdLevel === 'number' &&
        typeof id !== 'number'
    ) {
        const targetedMonth = periodes.length
            ? periodes.find((item) => String(item.code) === event.target.value)
            : {};
        if (names.length > 1 && event.target.name.includes('|')) {
            const [f, s] = names;
            values[index].details[indexAtSecondLevel].periodes[
                indexAtThirdLevel
            ][f] = targetedMonth?.dateDebut || event.target.value;
            values[index].details[indexAtSecondLevel].periodes[
                indexAtThirdLevel
            ][s] = targetedMonth?.dateFin || event.target.value;
            values[index].details[indexAtSecondLevel].periodes[
                indexAtThirdLevel
            ].mois = targetedMonth?.mois?.toUpperCase() || '';
        } else if (names.length > 1 && event.target.name.includes('.')) {
            const [f, s] = names;
            values[index].details[indexAtSecondLevel].periodes[
                indexAtThirdLevel
            ][f][s] = event.target.value;
        } else {
            values[index].details[indexAtSecondLevel].periodes[
                indexAtThirdLevel
            ][event.target.name] = event.target.value;
        }
    }
    /**
     * @desc: A ce stade, nous traitons toutes les valeurs
     * au quatrième niveau de l'arborescence (Voir le state)
     */
    if (
        typeof index === 'number' &&
        event &&
        typeof indexAtSecondLevel === 'number' &&
        typeof indexAtThirdLevel === 'number' &&
        typeof id === 'number'
    ) {
        if (names.length > 1 && event.target.name.includes('|')) {
            const [f, s] = names;

            // Old Logic
            // values[index].details[indexAtSecondLevel].periodes[
            //     indexAtThirdLevel
            // ].beneficiaires[id][f] = event.target.value;
            // values[index].details[indexAtSecondLevel].periodes[
            //     indexAtThirdLevel
            // ].beneficiaires[id][s] =
            //     typeof event.target.value === 'string'
            //         ? event.target.selectedOptions['0'].innerText
            //         : event.target.value + 9999;
            // New logic
            if (f.includes('.')) {
                const [newFf, newFS] = getNames({ name: f });
                values[index].details[indexAtSecondLevel].periodes[
                    indexAtThirdLevel
                ].beneficiaires[id][newFf][newFS] = event.target.value;
            }
            if (s.includes('.')) {
                const [newSf, newSs] = getNames({ name: s });
                values[index].details[indexAtSecondLevel].periodes[
                    indexAtThirdLevel
                ].beneficiaires[id][newSf][newSs] =
                    typeof event.target.value === 'string'
                        ? event.target.selectedOptions['0'].innerText
                        : event.target.value + 9999;
            }
            if (!f.includes('.') && !s.includes('.')) {
                values[index].details[indexAtSecondLevel].periodes[
                    indexAtThirdLevel
                ].beneficiaires[id][f] = event.target.value;
                values[index].details[indexAtSecondLevel].periodes[
                    indexAtThirdLevel
                ].beneficiaires[id][s] =
                    typeof event.target.value === 'string'
                        ? event.target.selectedOptions['0'].innerText
                        : event.target.value + 9999;
            }
        } else if (names.length > 1 && event.target.name.includes('.')) {
            const [f, s] = names;
            values[index].details[indexAtSecondLevel].periodes[
                indexAtThirdLevel
            ].beneficiaires[id][f][s] = event.target.value;
        } else {
            values[index].details[indexAtSecondLevel].periodes[
                indexAtThirdLevel
            ].beneficiaires[id][event.target.name] = event.target.value;
        }
    }

    setFormFields(values);
};
