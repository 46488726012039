/* eslint-disable react/no-array-index-key */
import { Fragment, useCallback, useEffect, useState } from 'react';
// import { useLocation, useNavigate } from 'react-router-dom';
import {
    submitPostAction,
    useFetchAndListening,
    useFormGlobalReducer,
    useGetUserDetail
} from '@napp-inc/jnapp-hook';
import {
    DB_NODE,
    TIME,
    formInitialState,
    BUDGET_ELABORATION,
    TYPE_BUDGET_ADDITIONNEL,
    TYPE_BUDGET_PREVISIONNEL,
    CHARGE,
    BUDGET_AMENDEMENT,
    BENEFICIAIRE,
    ETAT
} from '@napp-inc/jnapp-util';
import { useDispatch, useSelector } from 'react-redux';
import { IoIosAddCircleOutline } from 'react-icons/io';
import { FaRegFilePdf } from 'react-icons/fa';
import generatePDF, { Resolution, Margin } from 'react-to-pdf';
import { URL_CONST } from '../../../../../util';
import { REDUX_NODE_NAME } from '../../../../../redux';
import {
    BackComponent,
    ConditionalRenderingWrapper,
    CustomCenteredModal,
    CustomCheckButton,
    CustomDropdown,
    CustomSwitchButton,
    FormWrapper
} from '../../../../../components';
import {
    ChargeFixeBaseForm,
    ElaborationTablePreview,
    LeftSidePannel
} from '../base';
import {
    handleAddHighLevel,
    handleChange,
    handleRemove,
    handleSelectChange,
    toggledView,
    toggledVisibilityView,
    useGetTypeBeneficiaires
} from '../util';
import { useGetPeriodesExercice } from '../util/hooks/use-get-periodes';
import { useGeneratePayloadAndDataList } from '../util/hooks';

/**
 * @description fields du state du formulaire
 */
const defaultFields = {
    selectedExercice: {},
    selectedDirection: {},
    estPrevisionnel: true,
    isDraft: false
};

/**
 * @description dependencies du state du formulaire
 */
const defaultDependencies = {
    fields: [
        'directions',
        'secteurs',
        'charges',
        'caisses',
        'exercicesFinancier',
        'employes',
        'users',
        'partenaires',
        'brouillon'
    ]
};

/**
 * Principale vue des vues pour les charges fixes
 * @param {Object} param0 : prends en compte toutes les props possibles
 * @param {String} param0.typeBudget: qui sera du type 'Elaboration | Completion | Ammendement'
 * @param {String} param0.isAdditionnel: qui sera du type true | False, pour savoir si c'est un prévisionnel ou un additionnel
 * @param {String} param0.isCompletion: qui sera du type true | False, pour savoir si on fait la completion d'une élaboration existante
 * @returns
 */
export function ChargesFixes({
    isAdditionnel = false,
    isCompletion = false,
    isAmendement = false,
    idEntity,
    payloadUrl
}) {
    const reduxDispatcher = useDispatch();
    // const navigate = useNavigate();
    // const path = useLocation();
    const [show, setShow] = useState(false);
    // const [idTarget, setIdTarget] = useState(null);
    const onHide = () => setShow(false);
    const onShow = () => setShow(true);
    // Begin Region
    const [formFields, setFormFields] = useState([
        {
            element: {
                code: '',
                designation: '' /** Property to add for displaying purpose */
            },
            typeMoyenPaiement: '',
            idCaisse: '',
            caisseDesignation: '' /** Property to add for displaying purpose */,
            estQuantite: false /** Property to add for displaying purpose and logic payload (backend) */,
            estRempliAutomatique: false /** Property to add for displaying purpose and logic payload (backend) */,
            prix: 0,
            isTooggled: true,
            isVisible: true,
            details: [
                {
                    direction: '',
                    directionDesignation:
                        '' /** Property to add for displaying purpose */,
                    secteur: {
                        code: '',
                        designation: ''
                    },
                    isTooggled: true,
                    isVisible: false,
                    periodes: [
                        {
                            dateDebut: 0,
                            dateFin: 0,
                            datePaiement: 0,
                            datePaiementString: '',
                            mois: '' /** Property to add for displaying month */,
                            isTooggled: true,
                            isVisible: false,
                            beneficiaires: [
                                {
                                    typeEntite: '',
                                    typeEntiteDesignation:
                                        '' /** Property to add for displaying purpose */,
                                    valeurTypeEntite: {
                                        reference: '',
                                        designation:
                                            '' /** Property to add for displaying purpose */
                                    },
                                    quantite: 0,
                                    montant: 0
                                }
                            ]
                        }
                    ]
                }
            ]
        }
    ]);
    // End Region

    const {
        firebaseUser,
        charges,
        etatCharge,
        secteurs,
        etatSecteurs,
        directions,
        etatDirections,
        caisses,
        etatCaisse,
        exerciceFinancier,
        etatExerciceFinancier,
        users,
        etatUsers,
        employesOrganisation,
        etatEmployesOrganisation,
        partenaire,
        etatPartenaire,
        brouillons,
        etatBrouillon
    } = useSelector((reduxState) => ({
        firebaseUser: reduxState.firebaseUser,
        charges: reduxState.chargesFixe,
        etatCharge: reduxState.etat.chargesFixe.etat,
        secteurs: reduxState.secteurs,
        etatSecteurs: reduxState.etat.secteurs.etat,
        directions: reduxState.directions,
        etatDirections: reduxState.etat.directions.etat,
        caisses: reduxState.caissesSectorielle.map((item) => ({
            ...item,
            designation: `${item?.valeurTypeEntite?.designation}-${item?.devise}`
        })),
        etatCaisse: reduxState.etat.caissesSectorielle.etat,
        exerciceFinancier: reduxState.exerciceFinancier,
        etatExerciceFinancier: reduxState.etat.exerciceFinancier.etat,

        users: reduxState.users.map((item) => ({
            ...item,
            typeBeneficiaire: BENEFICIAIRE.USER.code,
            designation: `${item?.personne?.prenom || ' '} ${
                item?.personne?.nom || ' '
            }`
        })),
        etatUsers: reduxState.etat.users.etat,
        employesOrganisation: reduxState.employesOrganisation
            .filter((item) => !item?.idUser)
            .map((item) => ({
                ...item,
                typeBeneficiaire: BENEFICIAIRE.EMPLOYE.code,
                designation: `${item?.personne?.prenom || ' '} ${
                    item?.personne?.nom || ' '
                }`
            })),
        etatEmployesOrganisation: reduxState.etat.employesOrganisation.etat,
        partenaire: reduxState.beneficiaires.map((item) => ({
            ...item,
            typeBeneficiaire: BENEFICIAIRE.PARTENAIRE.code,
            designation: `${item?.personne?.prenom || ' '} ${
                item?.personne?.nom || ' '
            }`
        })),
        etatPartenaire: reduxState.etat.beneficiaires.etat,
        brouillons: reduxState.brouillonElaborationAmendement.filter(
            (item) =>
                item.type ===
                    (isAdditionnel
                        ? TYPE_BUDGET_ADDITIONNEL.code
                        : TYPE_BUDGET_PREVISIONNEL.code) &&
                item.typeEntite ===
                    (isAmendement ? BUDGET_AMENDEMENT : BUDGET_ELABORATION) &&
                !item.estVariable &&
                item.etat === ETAT.ETAT_ENCOURS
        ),
        etatBrouillon: reduxState.etat.brouillonElaborationAmendement.etat
    }));

    /**
     * @description: exercice financier format
     */
    const exercices = exerciceFinancier.map((item) => ({
        ...item,
        designation: `${TIME.fromUtcTimestampToLocalString(
            item.dateDebut
        )} - ${TIME.fromUtcTimestampToLocalString(item.dateFin)}`
    }));

    const { idToken, creator } = useGetUserDetail({ firebaseUser });
    const initialState = formInitialState({
        fields: defaultFields,
        dependencies: defaultDependencies
    });
    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        {
            initialState
        }
    );
    const getTypeBeneficiaire = useGetTypeBeneficiaires({
        employes: employesOrganisation,
        users,
        partenaires: partenaire
    });
    const { fields, form /** elements */ } = formState;

    const exerciceCible = exercices.find(
        (item) => item.id === fields?.selectedExercice.id
    );
    const periodes = useGetPeriodesExercice({
        exercice: exerciceCible,
        payloadUrl
    });

    useFetchAndListening({
        idToken,
        reduxDispatcher,
        list: [
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_CHARGE_FIXE,
                nodeName: REDUX_NODE_NAME.CHARGE_FIXE,
                etat: etatCharge
            },
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_EXERCICE,
                nodeName: DB_NODE.EXERCICE_FINANCIER,
                etat: etatExerciceFinancier
            },
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_DIRECTION, // Toutes les directions
                nodeName: REDUX_NODE_NAME.DIRECTION,
                etat: etatDirections
            },
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_SECTEUR_CHARGE, // secteur Napp
                nodeName: REDUX_NODE_NAME.SECTEUR,
                etat: etatSecteurs
            },
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_CAISSE_SECTORIELLE,
                nodeName: REDUX_NODE_NAME.CAISSE_SECTORIELLE,
                etat: etatCaisse
            },
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_USER,
                nodeName: DB_NODE.USER,
                etat: etatUsers
            },
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_EMPLOYE_ORGANISATION,
                nodeName: DB_NODE.EMPLOYE,
                etat: etatEmployesOrganisation
            },
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_PARTENAIRE,
                nodeName: REDUX_NODE_NAME.BENEFICIAIRE,
                etat: etatPartenaire
            },
            {
                isOnlyFetch: true,
                functionName:
                    URL_CONST.GET_LIST_BROUILLON_ELABORATION_AMENDEMENT_INITIALS,
                nodeName: DB_NODE.BROUILLON_ELABORATION_AMENDEMENT,
                etat: etatBrouillon,
                payload: {
                    newElaborationAmendement: {
                        // typeEntite: isAmendement
                        //     ? BUDGET_AMENDEMENT
                        //     : BUDGET_ELABORATION,
                        typeProprietaire: CHARGE.code,
                        estVariable: false
                        // type: isAdditionnel
                        //     ? TYPE_BUDGET_ADDITIONNEL.code
                        //     : TYPE_BUDGET_PREVISIONNEL.code
                    }
                }
            }
        ]
    });

    /**
     * End Region
     */

    /**
     * Fonction pour elaborarer le tableau de prévisualisation
     */
    const tablePreviewData = ({ list = [] }) => {
        if (!list.length) return [];
        const data = [];
        list.forEach((item) => {
            const element = {
                charge: item.element,
                moyenPaiement: item.typeMoyenPaiement,
                synthese: {
                    JANVIER: 0,
                    FEVRIER: 0,
                    MARS: 0,
                    AVRIL: 0,
                    MAI: 0,
                    JUIN: 0,
                    JUILLET: 0,
                    AOUT: 0,
                    SEPTEMBRE: 0,
                    OCTOBRE: 0,
                    NOVEMBRE: 0,
                    DECEMBRE: 0
                }
            };
            item.details.forEach((detail) => {
                const detailPeriodes = detail.periodes;
                detailPeriodes.forEach((dtp) => {
                    const month = dtp.mois;
                    const prix = item.prix || 0;
                    const montantTotal = prix
                        ? prix *
                          dtp.beneficiaires.reduce(
                              (a, b) => a + (b.quantite || 0),
                              0
                          )
                        : dtp.beneficiaires.reduce(
                              (a, b) => a + (b.montant || 0),
                              0
                          );
                    element.synthese[month] =
                        (element.synthese[month] || 0) + montantTotal;
                });
            });
            data.push(element);
        });
        return data;
    };

    const { payloadList, tableList } = useGeneratePayloadAndDataList({
        formFields,
        tablePreviewData
    });

    /**
     * Fonction qui permet de scroller jusqu'à un élément précis
     */
    // const smoothScroll = ({ idDocument }) => {
    //     const element = document.getElementById(idDocument);
    //     if (element) {
    //         element.scrollIntoView({top: 56, behavior: 'smooth', block: 'start' });
    //     }
    // };

    // useEffect(() => {
    //     if (path.pathname && idTarget) {
    //         smoothScroll({ idDocument: idTarget });
    //     }
    // }, [path, idTarget]);

    const memoisedDraft = useCallback(
        ({ draft }) => {
            if (
                draft.length &&
                ((exerciceCible?.dateDebut && exerciceCible?.dateFin) ||
                    (payloadUrl?.start && payloadUrl?.end))
            ) {
                const findDraft = draft.find(
                    (item) =>
                        item.periode.dateDebut ===
                            (exerciceCible?.dateDebut || payloadUrl?.start) &&
                        item.periode.dateFin ===
                            (exerciceCible?.dateFin || payloadUrl?.end) &&
                        item?.estPrevisionnel ===
                            (isAdditionnel ? fields.estPrevisionnel : false)
                );
                const parsedBruillon = findDraft?.brouillon?.list || [];
                setFormFields([
                    ...parsedBruillon,
                    {
                        element: {
                            code: '',
                            designation:
                                '' /** Property to add for displaying purpose */
                        },
                        typeMoyenPaiement: '',
                        idCaisse: '',
                        caisseDesignation:
                            '' /** Property to add for displaying purpose */,
                        estQuantite: false /** Property to add for displaying purpose and logic payload (backend) */,
                        estRempliAutomatique: false /** Property to add for displaying purpose and logic payload (backend) */,
                        prix: 0,
                        isTooggled: true,
                        isVisible: true,
                        details: [
                            {
                                direction: '',
                                directionDesignation:
                                    '' /** Property to add for displaying purpose */,
                                secteur: {
                                    code: '',
                                    designation: ''
                                },
                                isTooggled: true,
                                isVisible: false,
                                periodes: [
                                    {
                                        dateDebut: 0,
                                        dateFin: 0,
                                        datePaiement: 0,
                                        datePaiementString: '',
                                        mois: '' /** Property to add for displaying month */,
                                        isTooggled: true,
                                        isVisible: false,
                                        beneficiaires: [
                                            {
                                                typeEntite: '',
                                                typeEntiteDesignation:
                                                    '' /** Property to add for displaying purpose */,
                                                valeurTypeEntite: {
                                                    reference: '',
                                                    designation:
                                                        '' /** Property to add for displaying purpose */
                                                },
                                                quantite: 0,
                                                montant: 0
                                            }
                                        ]
                                    }
                                ]
                            }
                        ]
                    }
                ]);
            }
        },
        [
            exerciceCible?.dateDebut,
            exerciceCible?.dateFin,
            fields.estPrevisionnel,
            isAdditionnel,
            payloadUrl?.end,
            payloadUrl?.start
        ]
    );

    /**
     * UseEffect pour mettre à jour le state au cas où un brouillon existe
     */
    useEffect(() => {
        memoisedDraft({ draft: brouillons || [] });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [memoisedDraft]);

    const handleSubmit = (e) => {
        e.preventDefault();
        /**
         * @description : Payload
         */
        const payload = {
            periode: {
                dateDebut: exerciceCible.dateDebut || payloadUrl?.start,
                dateFin: exerciceCible.dateFin || payloadUrl?.end
            },
            newElaborationAmendement: {
                typeEntite: isAmendement
                    ? BUDGET_AMENDEMENT
                    : BUDGET_ELABORATION,
                typeProprietaire: CHARGE.code,
                estVariable: false,
                type: isAdditionnel
                    ? TYPE_BUDGET_ADDITIONNEL.code
                    : TYPE_BUDGET_PREVISIONNEL.code
            },
            creator
        };
        if (isAdditionnel) {
            payload.newElaborationAmendement.estPrevisionnel =
                fields.estPrevisionnel;
        }
        if ((isCompletion || isAmendement) && idEntity) {
            payload.idElaborationAmendement = idEntity;
        }
        if (formFields.length) {
            if (!fields.isDraft) {
                payload.list = payloadList;
            }
            if (fields.isDraft) {
                payload.elemUpdate = {
                    list: formFields.filter((item) => item.element.code)
                };
            }
        }
        if (fields.isDraft) {
            formDispatcher(
                submitPostAction({
                    idToken,
                    functionName:
                        URL_CONST.POST_BROUILLON_ELABORATION_AMENDEMENT,
                    reduxNodeName: DB_NODE.BROUILLON_ELABORATION_AMENDEMENT,
                    payload,
                    fields,
                    reduxDispatcher
                    // uiValidator
                })
            );
        }
        if (!fields.isDraft) {
            formDispatcher(
                submitPostAction({
                    idToken,
                    functionName: URL_CONST.POST_ELABORATION_AMENDEMENT,
                    reduxNodeName: DB_NODE.ELABORATION_AMENDEMENT,
                    payload,
                    fields,
                    reduxDispatcher
                    // uiValidator
                })
            );
        }
    };

    /**
     * Fonction de suppression d'une charge à partir du pannel
     * @param {*} param0
     */
    // const removeView = ({ code }) => {
    //     const index = formFields.map((item) => item.element.code).indexOf(code);
    //     handleRemove(index);
    // };

    /**
     * Options définies pour la prévisualisation en pdf
     */
    const options = {
        // default is `save`
        method: 'open',
        // default is Resolution.MEDIUM = 3, which should be enough, higher values
        // increases the image quality but also the size of the PDF, so be careful
        // using values higher than 10 when having multiple pages generated, it
        // might cause the page to crash or hang.
        resolution: Resolution.HIGH,
        page: {
            // margin is in MM, default is Margin.NONE = 0
            margin: Margin.SMALL,
            // default is 'A4'
            format: 'letter',
            // default is 'portrait'
            orientation: 'landscape'
        },
        canvas: {
            // default is 'image/jpeg' for better size performance
            mimeType: 'image/png',
            qualityRatio: 1
        },
        // Customize any value passed to the jsPDF instance and html2canvas
        // function. You probably will not need this and things can break,
        // so use with caution.
        overrides: {
            // see https://artskydj.github.io/jsPDF/docs/jsPDF.html for more options
            pdf: {
                compress: true
            },
            // see https://html2canvas.hertzen.com/configuration for more options
            canvas: {
                useCORS: true
            }
        }
    };
    // you can use a function to return the target element besides using React refs
    const getTargetElement = () => document.getElementById('content-id');
    const renderContent = () => (
        <>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 border-bottom">
                <BackComponent className="btn btn-primary" />
                <ConditionalRenderingWrapper isShouldBeRendered={!idEntity}>
                    <div className="d-flex justify-content-between align-items-center col-4">
                        <CustomDropdown
                            options={exercices}
                            formDispatcher={formDispatcher}
                            formState={formState}
                            defaultOption="Sélectionner un exercice"
                            label="Exercice: "
                            selectionClassName="form-select-sm fw-bold border-dark"
                            labelClassName="col-12 col-sm-2 col-md-2 fs-5 me-5 fw-bold"
                            divClassName="col-12 col-sm-10 col-md-10"
                            name="selectedExercice"
                            id="selectedExerciceId"
                            idProperty="id"
                            // uiValidator={uiValidator}
                            // callback={onChangeAfterSelect}
                            // resetOnChangeState
                            // fieldsReseted={{
                            //     ...initialState.fields,
                            //     selectedExercice: fields.selectedExercice,
                            //     chargeElaboration: fields.chargeElaboration
                            // }}
                        />
                    </div>
                </ConditionalRenderingWrapper>
                <ConditionalRenderingWrapper isShouldBeRendered={isAdditionnel}>
                    {/* <CustomCheckButton
                        // uiValidator={uiValidator}
                        formDispatcher={formDispatcher}
                        name="estPrevisionnel"
                        id="selectionElaborationAdditionnelSwitchId"
                        label="Agir sur le Prévisionnel?"
                        labelClassName="fst-italic fw-bolder"
                        formState={formState}
                    /> */}
                    <CustomSwitchButton
                        id="selectionElaborationAdditionnelSwitchId"
                        name="estPrevisionnel"
                        text="Agir sur le Prévisionnel?"
                        value={fields.estPrevisionnel}
                        formState={formState}
                        formDispatcher={formDispatcher}
                        labelClassName="fst-italic fw-bolder"
                    />
                </ConditionalRenderingWrapper>
                <h3>Budget {isAdditionnel ? 'Additionnel' : 'Présionnel'}</h3>
            </div>
            <FormWrapper
                isRedirect={!isCompletion && !isAmendement}
                onSubmit={handleSubmit}
                formState={formState}
                isStepBack={isCompletion || isAmendement}
                formDispatcher={formDispatcher}
                listDependencies={[
                    {
                        dependency: 'directions',
                        etat: etatDirections
                    },
                    {
                        dependency: 'exercicesFinancier',
                        etat: etatExerciceFinancier
                    },
                    {
                        dependency: 'secteurs',
                        etat: etatSecteurs
                    },
                    {
                        dependency: 'charges',
                        etat: etatCharge
                    },
                    {
                        dependency: 'caisses',
                        etat: etatCaisse
                    },
                    {
                        dependency: 'employes',
                        etat: etatEmployesOrganisation
                    },
                    {
                        dependency: 'users',
                        etat: etatUsers
                    },
                    {
                        dependency: 'partenaires',
                        etat: etatPartenaire
                    },
                    {
                        dependency: 'brouillon',
                        etat: etatBrouillon
                    }
                ]}
            >
                <div className="d-flex">
                    <LeftSidePannel
                        onShow={onShow}
                        formFields={formFields}
                        toggledVisibilityView={toggledVisibilityView}
                        toggledView={toggledView}
                        handleSubmit={handleSubmit}
                        form={form}
                        setFormFields={setFormFields}
                        handleRemove={handleRemove}
                        draftComponentAction={
                            <CustomCheckButton
                                // uiValidator={uiValidator}
                                formDispatcher={formDispatcher}
                                name="isDraft"
                                id="draftChargeComponentId"
                                label="Voulez-vous sauvegarder le travail dans un brouillon?"
                                formState={formState}
                            />
                        }
                    />
                    <div className="col-9 ms-2">
                        <CustomCenteredModal
                            show={show}
                            onHide={onHide}
                            header="Elaboration"
                            fullscreen
                            // size="lg"
                            // backdrop={backdrop}
                        >
                            <div className="text-end mb-2">
                                <button
                                    className="btn btn-primary rounded-pill"
                                    type="button"
                                    onClick={() =>
                                        generatePDF(getTargetElement, options)
                                    }
                                >
                                    Visualiser <FaRegFilePdf />
                                </button>
                            </div>
                            <div id="content-id">
                                <ElaborationTablePreview data={tableList} />
                            </div>
                        </CustomCenteredModal>
                        <ConditionalRenderingWrapper
                            isShouldBeRendered={!!fields.selectedExercice.id}
                        >
                            {formFields.map((field, index) => (
                                <Fragment key={index}>
                                    {field.isTooggled ? (
                                        <ChargeFixeBaseForm
                                            caisses={caisses}
                                            charges={charges}
                                            directions={directions}
                                            field={field}
                                            formFields={formFields}
                                            setFormFields={setFormFields}
                                            getTypeBeneficiaire={
                                                getTypeBeneficiaire
                                            }
                                            handleAddHighLevel={
                                                handleAddHighLevel
                                            }
                                            handleChange={handleChange}
                                            handleRemove={handleRemove}
                                            handleSelectChange={
                                                handleSelectChange
                                            }
                                            index={index}
                                            periodes={periodes}
                                            secteurs={secteurs}
                                            onToggleView={toggledView}
                                        />
                                    ) : null}
                                </Fragment>
                            ))}
                            <IoIosAddCircleOutline
                                style={{ marginLeft: '5px' }}
                                role="button"
                                title="Ajouter Charge"
                                className="text-secondary fs-4"
                                onClick={() =>
                                    handleAddHighLevel({
                                        type: 'Charge',
                                        setFormFields,
                                        formFields
                                    })
                                }
                            />
                            {/* <div>
                                <StateShowerComponent state={formFields} />
                            </div> */}
                        </ConditionalRenderingWrapper>
                    </div>
                </div>
            </FormWrapper>
        </>
    );
    return renderContent();
}
