import { ListGenericForm } from '../../../components';
import { REDUX_NODE_NAME } from '../../../redux';
import { URL_CONST } from '../../../util';

export function ListCrediterBalanceLotForm() {
    return (
        <ListGenericForm
            reduxPropertyName="crediterBalanceLot"
            functionName={URL_CONST.GET_LIST_CREDITER_BALANCE_EN_LOT_INITIALS}
            nodeName={REDUX_NODE_NAME.CREDITER_BALANCE_LOT}
            mapper={[
                {
                    field: 'etat',
                    displayName: 'Etat'
                },
                {
                    fields: 'devises.CDF.nombre',
                    displayName: 'Nombre(CDF)'
                },
                {
                    fields: 'devises.CDF.montant',
                    displayName: 'Montant CDF'
                },
                {
                    fields: 'devises.USD.nombre',
                    displayName: 'Nombre(USD)'
                },
                {
                    fields: 'devises.USD.montant',
                    displayName: 'Montant USD'
                },
                {
                    displayName: 'Création',
                    field: 'dateCreation'
                }
            ]}
        />
    );
}
