// For input

import { castTypeOfData } from './cast-type-of-data';

/**
 * @description: Logique handleChange pour les inputs
 * @param {Object} param0 : Tout le paramètre
 * @param {Number} param0.index : Index de l'element au plus haut niveau de l'arborescence, voir le state
 * @param {Event} param0.event : Event de l'object input de HTML
 * @param {Number} param0.indexAtSecondLevel : Index de l'element au second niveau de l'arborescence, voir le state
 * @param {Number} param0.indexAtThirdLevel : Index de l'element au troisième niveau de l'arborescence, voir le state
 * @param {Number} param0.id : Index de l'element au quatrième niveau de l'arborescence, voir le state
 */
export const handleChange = ({
    setFormFields,
    formFields,
    index,
    event,
    indexAtSecondLevel,
    indexAtThirdLevel,
    id
}) => {
    const values = [...formFields];
    /**
     * @desc: A ce stade, nous traitons toutes les valeurs
     * au premier niveau de l'arborescence (Voir le state)
     */
    if (
        typeof index === 'number' &&
        event &&
        typeof indexAtSecondLevel !== 'number' &&
        typeof indexAtThirdLevel !== 'number' &&
        typeof id !== 'number'
    ) {
        values[index][event.target.name] = castTypeOfData({
            type: event.target.type,
            data: event.target.value
        });
    }
    /**
     * @desc: A ce stade, nous traitons toutes les valeurs
     * au deuxième niveau de l'arborescence (Voir le state)
     */
    if (
        typeof index === 'number' &&
        event &&
        typeof indexAtSecondLevel === 'number' &&
        typeof indexAtThirdLevel !== 'number' &&
        typeof id !== 'number'
    ) {
        values[index].details[indexAtSecondLevel][event.target.name] =
            castTypeOfData({
                type: event.target.type,
                data: event.target.value
            });
    }
    /**
     * @desc: A ce stade, nous traitons toutes les valeurs
     * au troisième niveau de l'arborescence (Voir le state)
     */
    if (
        typeof index === 'number' &&
        event &&
        typeof indexAtSecondLevel === 'number' &&
        typeof indexAtThirdLevel === 'number' &&
        typeof id !== 'number'
    ) {
        if (
            event.target.attributes?.customproperty?.nodeValue ===
            'datePaiement'
        ) {
            values[index].details[indexAtSecondLevel].periodes[
                indexAtThirdLevel
            ].datePaiement = castTypeOfData({
                type: event.target.type,
                data: event.target.value
            });
        }
        if (event.target.name === 'datePaiementString') {
            values[index].details[indexAtSecondLevel].periodes[
                indexAtThirdLevel
            ][event.target.name] = castTypeOfData({
                // type: event.target.type,
                data: event.target.value
            });
        } else {
            values[index].details[indexAtSecondLevel].periodes[
                indexAtThirdLevel
            ][event.target.name] = castTypeOfData({
                type: event.target.type,
                data: event.target.value
            });
        }
    }
    /**
     * @desc: A ce stade, nous traitons toutes les valeurs
     * au quatrième niveau de l'arborescence (Voir le state)
     */
    if (
        typeof index === 'number' &&
        event &&
        typeof indexAtSecondLevel === 'number' &&
        typeof indexAtThirdLevel === 'number' &&
        typeof id === 'number'
    ) {
        values[index].details[indexAtSecondLevel].periodes[
            indexAtThirdLevel
        ].beneficiaires[id][event.target.name] = castTypeOfData({
            type: event.target.type,
            data: event.target.value
        });
    }
    setFormFields(values);
};
