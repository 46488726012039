import { fetchElement, submitPutAction } from '@napp-inc/jnapp-hook';
import { CODE_SERVICE, ETAT, FORM_CONST } from '@napp-inc/jnapp-util';
import {
    CustomButtonLoader,
    CustomCol,
    CustomRow,
    DetailWrapper,
    SimpleTable,
    UserServiceWrapper
} from '../../../components';
import { URL_CONST, useGetDetailForm } from '../../../util';
import { schema } from './detail-fields';
import { REDUX_NODE_NAME } from '../../../redux';

export function DetailAutorisationLotReferenceForm() {
    const { id, reduxDispatcher, entity, idToken, formState, formDispatcher } =
        useGetDetailForm({
            nodeName: 'autorisationReference',
            defaultElements: ['retrait']
        });

    const { form, fields, elements } = formState;

    const onFetchDetailRetraitById = () => {
        if (!id) return;
        const { status: etat } = elements.retrait;
        formDispatcher(
            fetchElement({
                idToken,
                functionName: URL_CONST.GET_LIST_DETAIL_CREDITER_BALANCE_EN_LOT,
                elementPropertyName: 'retrait',
                etat,
                payload: {
                    idCrediterBalanceEnLot: id,
                    estAutorisationReference: true
                },
                reduxDispatcher
            })
        );
    };

    const updateEtat = (etat) => {
        const payload = { idAutorisationLotReference: entity?.id, etat };
        const actionEncours =
            ETAT.ETAT_ANNULER === etat ? ETAT.ETAT_ANNULER : ETAT.ETAT_VALIDER;
        formDispatcher({
            type: FORM_CONST.FIELD_CHANGE,
            payload: {
                field: 'actionEncours',
                data: actionEncours
            }
        });
        formDispatcher(
            submitPutAction({
                idToken,
                functionName: URL_CONST.PUT_AUTORISATION_LOT_REFERENCE,
                payload,
                fields,
                reduxNodeName: REDUX_NODE_NAME.AUTORISATION_REFERENCE,
                reduxDispatcher
            })
        );
    };
    const renderContent = () => (
        <DetailWrapper
            functionName={URL_CONST.GET_AUTORISATION_LOT_REFERENCE_BY_ID}
            nodeName={REDUX_NODE_NAME.AUTORISATION_REFERENCE}
            formDispatcher={formDispatcher}
            formState={formState}
            reduxDispatcher={reduxDispatcher}
            subtitle="Details"
            entity={entity}
            id={id}
            schema={schema}
            idToken={idToken}
        >
            <div>
                <CustomButtonLoader
                    type="button"
                    className="btn-success mt-2"
                    onClick={onFetchDetailRetraitById}
                    text="Voir Détails"
                    disabled={
                        elements?.retrait?.status ===
                        FORM_CONST.FETCH_ELEMENT_IN_PROGRESS
                    }
                />
                <SimpleTable
                    tableClassName=""
                    isShowDropButton={false}
                    data={elements?.retrait?.value || []}
                    identifier="id"
                    mapper={[
                        {
                            displayName: 'Client',
                            dataExtractor: (item) =>
                                `${item?.client?.designation || ''} ${
                                    item?.client?.numero || ''
                                }`
                        },
                        {
                            field: 'montant',
                            displayName: 'montant'
                        },
                        {
                            field: 'devise',
                            displayName: 'Devise'
                        },
                        {
                            field: 'numeroReference',
                            displayName: 'Reference'
                        },
                        {
                            field: 'etat',
                            displayName: 'Etat'
                        },
                        {
                            field: 'dateCreation',
                            displayName: 'date création'
                        }
                    ]}
                    filter={false}
                    isLinkToDetail={false}
                />
            </div>
            <UserServiceWrapper
                services={[
                    { code: 'test' },
                    CODE_SERVICE.CODE_SERVICE_SENDER
                        .VALIDER_AUTORISATION_REFERENCE
                ]}
            >
                <CustomRow
                    isShouldBeRendered={entity?.etat === ETAT.ETAT_ENCOURS}
                >
                    <CustomCol className="offset-4 offset-md-8 col-3 col-md-2">
                        <CustomButtonLoader
                            type="button"
                            className="btn-danger"
                            onClick={() => updateEtat(ETAT.ETAT_ANNULER)}
                            text="Annuler"
                            disabled={
                                form.status === FORM_CONST.MODIFICATION_EN_COURS
                            }
                            isActionEncours={
                                fields.actionEncours === ETAT.ETAT_ANNULER
                            }
                        />
                    </CustomCol>
                    <CustomCol className="col-3 col-md-2">
                        <CustomButtonLoader
                            type="button"
                            className="btn-success"
                            onClick={() => updateEtat(ETAT.ETAT_VALIDER)}
                            text="valider"
                            disabled={
                                form.status === FORM_CONST.MODIFICATION_EN_COURS
                            }
                            isActionEncours={
                                fields.actionEncours === ETAT.ETAT_VALIDER
                            }
                        />
                    </CustomCol>
                </CustomRow>
            </UserServiceWrapper>
        </DetailWrapper>
    );
    return renderContent();
}
