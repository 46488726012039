import { handleChange } from './input-handle-change';

/**
 * Fonction pour gérer la visibilité à partir du formulaire
 * @param {*} param0
 */
export const toggledView = ({
    code,
    index,
    indexAtSecondLevel,
    indexAtThirdLevel,
    isTargeted = false,
    formFields,
    setFormFields
}) => {
    if (
        code &&
        typeof index !== 'number' &&
        typeof indexAtSecondLevel !== 'number' &&
        typeof indexAtThirdLevel !== 'number'
    ) {
        const findedIndex = formFields
            .map((item) => item.element.code)
            .indexOf(code);
        if (
            !formFields?.[findedIndex]?.isTooggled &&
            !formFields.at(-1)?.element?.code
        ) {
            const values = formFields.splice(-1, 1);
            setFormFields([...values]);
        }
        if (
            !formFields?.[findedIndex]?.isTooggled &&
            formFields.at(-1)?.element?.code
        ) {
            handleChange({
                index: formFields[formFields.length - 1],
                event: {
                    target: {
                        name: 'isTooggled',
                        value: false
                    }
                },
                setFormFields,
                formFields
            });
        }
        handleChange({
            index: findedIndex,
            event: {
                target: {
                    name: 'isTooggled',
                    value: !formFields?.[findedIndex]?.isTooggled
                }
            },
            setFormFields,
            formFields
        });
    }
    if (
        code &&
        typeof index === 'number' &&
        typeof indexAtSecondLevel === 'number' &&
        typeof indexAtThirdLevel !== 'number'
    ) {
        if (isTargeted) {
            if (
                !formFields?.[index]?.isTooggled &&
                !formFields.at(-1)?.element?.code
            ) {
                const values = formFields.splice(-1, 1);
                setFormFields([...values]);
            }
            if (
                !formFields?.[index]?.isTooggled &&
                formFields.at(-1)?.element?.code
            ) {
                handleChange({
                    index: formFields[formFields.length - 1],
                    event: {
                        target: {
                            name: 'isTooggled',
                            value: false
                        }
                    },
                    setFormFields,
                    formFields
                });
            }
            handleChange({
                index,
                event: {
                    target: {
                        name: 'isTooggled',
                        value: !formFields?.[index]?.isTooggled
                    }
                },
                setFormFields,
                formFields
            });
            handleChange({
                index,
                event: {
                    target: {
                        name: 'isTooggled',
                        value: !formFields[index].details[indexAtSecondLevel]
                            .isTooggled
                    }
                },
                indexAtSecondLevel,
                setFormFields,
                formFields
            });
            // setIdTarget(`${code}${index}${indexAtSecondLevel}`);
        }
        handleChange({
            index,
            event: {
                target: {
                    name: 'isTooggled',
                    value: !formFields[index].details[indexAtSecondLevel]
                        .isTooggled
                }
            },
            indexAtSecondLevel,
            setFormFields,
            formFields
        });
    }
    if (
        code &&
        typeof index === 'number' &&
        typeof indexAtSecondLevel === 'number' &&
        typeof indexAtThirdLevel === 'number'
    ) {
        if (isTargeted) {
            if (
                !formFields?.[index]?.isTooggled &&
                !formFields.at(-1)?.element?.code
            ) {
                const values = formFields.splice(-1, 1);
                setFormFields([...values]);
            }
            if (
                !formFields?.[index]?.isTooggled &&
                formFields.at(-1)?.element?.code
            ) {
                handleChange({
                    index: formFields[formFields.length - 1],
                    event: {
                        target: {
                            name: 'isTooggled',
                            value: false
                        }
                    },
                    setFormFields,
                    formFields
                });
            }
            handleChange({
                index,
                event: {
                    target: {
                        name: 'isTooggled',
                        value: !formFields?.[index]?.isTooggled
                    }
                },
                setFormFields,
                formFields
            });
            handleChange({
                index,
                event: {
                    target: {
                        name: 'isTooggled',
                        value: !formFields[index].details[indexAtSecondLevel]
                            .periodes[indexAtThirdLevel]?.isTooggled
                    }
                },
                indexAtSecondLevel,
                indexAtThirdLevel,
                setFormFields,
                formFields
            });
            // setIdTarget(
            //     `${code}${index}${indexAtSecondLevel}${indexAtThirdLevel}`
            // );
        }
        handleChange({
            index,
            event: {
                target: {
                    name: 'isTooggled',
                    value: !formFields[index].details[indexAtSecondLevel]
                        .periodes[indexAtThirdLevel]?.isTooggled
                }
            },
            indexAtSecondLevel,
            indexAtThirdLevel,
            setFormFields,
            formFields
        });
    }
};

/**
 * Fonction pour le pannel à gauche de l'écran qui va reprendre les charges
 * Elle servira à gérer la visibilité de l'arborescence
 * @param {} param0
 */
export const toggledVisibilityView = ({
    code,
    index,
    indexAtSecondLevel,
    indexAtThirdLevel,
    formFields,
    setFormFields
}) => {
    if (
        code &&
        typeof index !== 'number' &&
        typeof indexAtSecondLevel !== 'number' &&
        typeof indexAtThirdLevel !== 'number'
    ) {
        const findedIndex = formFields
            .map((item) => item.element.code)
            .indexOf(code);
        const value = !formFields?.[findedIndex].isVisible;
        handleChange({
            index: findedIndex,
            event: {
                target: {
                    name: 'isVisible',
                    value
                }
            },
            setFormFields,
            formFields
        });
    }
    if (
        code &&
        typeof index === 'number' &&
        typeof indexAtSecondLevel === 'number' &&
        typeof indexAtThirdLevel !== 'number'
    ) {
        const value = !formFields[index].details[indexAtSecondLevel].isVisible;
        handleChange({
            index,
            event: {
                target: {
                    name: 'isVisible',
                    value
                }
            },
            indexAtSecondLevel,
            setFormFields,
            formFields
        });
        handleChange({
            index,
            event: {
                target: {
                    name: 'isTooggled',
                    value
                }
            },
            indexAtSecondLevel,
            setFormFields,
            formFields
        });
    }
    if (
        code &&
        typeof index === 'number' &&
        typeof indexAtSecondLevel === 'number' &&
        typeof indexAtThirdLevel === 'number'
    ) {
        const value =
            !formFields[index].details[indexAtSecondLevel].periodes[
                indexAtThirdLevel
            ].isVisible;
        handleChange({
            index,
            event: {
                target: {
                    name: 'isVisible',
                    value
                }
            },
            indexAtSecondLevel,
            indexAtThirdLevel,
            setFormFields,
            formFields
        });
        handleChange({
            index,
            event: {
                target: {
                    name: 'isTooggled',
                    value
                }
            },
            indexAtSecondLevel,
            indexAtThirdLevel,
            setFormFields,
            formFields
        });
    }
};
