/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/label-has-associated-control */
import {
    submitPostAction,
    useFetchAndListening,
    useFormGlobalReducer,
    useGetUserDetail
} from '@napp-inc/jnapp-hook';
import { formInitialState, FORM_CONST } from '@napp-inc/jnapp-util';
import { useDispatch, useSelector } from 'react-redux';
import {
    CustomButtonLoader,
    CustomCol,
    CustomInput,
    CustomListReferenceGroupSelection,
    CustomRow,
    FormWrapper
} from '../../../components';
import { REDUX_NODE_NAME } from '../../../redux';
import { fuzzySearch, URL_CONST } from '../../../util';
import { mapper } from './util';

const defaultFields = {
    recherche: '',
    list: []
};

const defaultDependencies = { fields: ['retraitAutorisationLotReference'] };

export function CreationAutorisationLotReferenceForm() {
    const reduxDispatcher = useDispatch();
    /**
     * Get data on redux store
     */
    const { firebaseUser, dynamicNode, dynamicEtatNode } = useSelector(
        (reduxState) => ({
            firebaseUser: reduxState.firebaseUser,
            dynamicNode: reduxState.dynamicNode,
            dynamicEtatNode: reduxState.dynamicEtatNode
        })
    );
    const dynamicNodeNameRetrait = `retraitAutorisationLotReference`;
    /**
     * Common form processes
     */
    const { idToken } = useGetUserDetail({ firebaseUser });
    const initialState = formInitialState({
        fields: defaultFields,
        dependencies: defaultDependencies
    });
    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        {
            initialState
        }
    );
    const { fields, form } = formState;

    const resultat = fuzzySearch({
        array: dynamicNode[dynamicNodeNameRetrait],
        keys: [
            'etat',
            'numeroReference',
            'client.numero',
            'devise',
            'marchand.designation',
            'montant'
        ],
        word: fields.recherche
    });

    /**
     * Recupération des données nécessaires
     */
    useFetchAndListening({
        idToken,
        reduxDispatcher,
        list: [
            {
                isOnlyFetch: true,
                dynamicNodeName: dynamicNodeNameRetrait,
                isDynamicNode: true,
                functionName:
                    URL_CONST.GET_LIST_RETRAIT_CREDITER_BALANCE_EN_LOT,
                etat: dynamicEtatNode[dynamicNodeNameRetrait]?.etat,
                payload: {
                    estAutorisationReference: true
                },
                isCondition: !!dynamicNodeNameRetrait
            }
        ]
    });

    const handleChange = (e) => {
        const { id } = e.target;
        const existe = fields?.list?.find((item) => item === id);
        if (existe) {
            formDispatcher({
                type: FORM_CONST.REMOVE_PRIMITIVE_ITEM,
                payload: {
                    field: 'list',
                    data: id
                }
            });
        } else {
            formDispatcher({
                type: FORM_CONST.ADD_PRIMITIVE_ITEM,
                payload: {
                    field: 'list',
                    data: id
                }
            });
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const { list } = fields;

        if (!list.length) return;

        const payload = {
            list
        };
        formDispatcher(
            submitPostAction({
                idToken,
                functionName: URL_CONST.POST_AUTORISATION_LOT_REFERENCE,
                payload,
                fields,
                reduxNodeName: REDUX_NODE_NAME.AUTORISATION_REFERENCE,
                reduxDispatcher
            })
        );
    };
    const renderContent = () => (
        <FormWrapper
            formState={formState}
            formDispatcher={formDispatcher}
            listDependencies={[
                {
                    dependency: 'retraitAutorisationLotReference',
                    etat: dynamicEtatNode[dynamicNodeNameRetrait]?.etat,
                    isCondition: !!dynamicNodeNameRetrait
                }
            ]}
        >
            <div>
                <div className="row">
                    <div className="mb-3 col-sm-5">
                        <CustomInput
                            inputClassName="form-control-sm"
                            placeholder="Recherche..."
                            formDispatcher={formDispatcher}
                            formState={formState}
                            name="recherche"
                        />
                    </div>
                    <div className="col-sm-5">
                        Résultat :{' '}
                        <span className="badge bg-primary">
                            {resultat?.length || 0}
                        </span>
                    </div>
                </div>
                <div
                    style={{
                        maxHeight: '300px',
                        overflow: 'auto'
                    }}
                    className="mb-3"
                >
                    <CustomListReferenceGroupSelection
                        data={resultat}
                        fields={fields}
                        handleChange={handleChange}
                        mapper={mapper}
                        property="list"
                    />
                </div>
            </div>
            <CustomRow className="row">
                <CustomCol className="col-3">
                    <CustomButtonLoader
                        className="btn-success"
                        onClick={handleSubmit}
                        text="Sauvegarder"
                        disabled={form.status === FORM_CONST.CREATION_EN_COURS}
                    />
                </CustomCol>
            </CustomRow>
        </FormWrapper>
    );
    return renderContent();
}
