import { MOYEN_PAIEMENT } from '@napp-inc/jnapp-util';
import React from 'react';
import { AiFillMinusCircle } from 'react-icons/ai';
import {
    CustomLiteDynamicDropdown,
    CustomLiteDynamicInput
} from '../../../../../../components';

export function ChargeFixeRowSelector({
    handleRemove,
    getClassNames,
    field,
    charges,
    handleSelectChange,
    caisses,
    index,
    handleChange,
    formFields,
    setFormFields
}) {
    return (
        <div className="row d-flex align-items-center justify-content-between">
            {/* Charges */}
            <CustomLiteDynamicDropdown
                containerClassName={
                    getClassNames({
                        moyenPaiement: field?.typeMoyenPaiement,
                        estQuantite: charges.find(
                            (charge) => charge.code === field.element.code
                        )?.estQuantite
                    })?.chargeClass
                }
                id={`${field.element.code}`}
                ariaLabel="Default select example"
                name="element.code|element.designation"
                customproperty="estQuantite"
                customtypecharge="estRempliAutomatique"
                value={field?.element?.code}
                options={charges}
                hiddenOption="Selectionnez une charge"
                isSort
                onChange={handleSelectChange}
                handleProps={{ index, setFormFields, formFields, charges }}
                labelStyle={{
                    fontSize: '13px',
                    paddingBottom: '8px',
                    lineHeight: '20px',
                    minHeight: '20px',
                    display: 'block',
                    color: 'rgba(0,0,0,0.5)'
                }}
                label="Charge"
                selectClassName="form-select form-select-sm col-3"
            />
            {/* Mode Paiement */}
            <CustomLiteDynamicDropdown
                containerClassName={
                    getClassNames({
                        moyenPaiement: field?.typeMoyenPaiement,
                        estQuantite: charges.find(
                            (charge) => charge.code === field.element.code
                        )?.estQuantite
                    })?.modePaiementClass
                }
                ariaLabel="Mode Paiement Selector"
                name="typeMoyenPaiement"
                customproperty="estMoyenPaiement"
                value={field?.typeMoyenPaiement}
                options={MOYEN_PAIEMENT.MOYEN_PAIEMENT_CHARGE_FIXE}
                hiddenOption="Mode de Paiement"
                onChange={handleSelectChange}
                handleProps={{ index, setFormFields, formFields }}
                labelStyle={{
                    fontSize: '13px',
                    paddingBottom: '8px',
                    lineHeight: '20px',
                    minHeight: '20px',
                    display: 'block',
                    color: 'rgba(0,0,0,0.5)'
                }}
                label="Mode de Paiement"
                selectClassName="form-select form-select-sm col-3"
            />
            {/* Caisse Selector */}
            {field?.typeMoyenPaiement === MOYEN_PAIEMENT.CASH ? (
                <CustomLiteDynamicDropdown
                    containerClassName={
                        getClassNames({
                            moyenPaiement: field?.typeMoyenPaiement,
                            estQuantite: charges.find(
                                (charge) => charge.code === field.element.code
                            )?.estQuantite
                        })?.caisseClass
                    }
                    ariaLabel="Caisse Selector"
                    name="idCaisse|caisseDesignation"
                    value={field?.idCaisse}
                    options={
                        caisses.filter(
                            (caisse) =>
                                caisse.devise ===
                                charges.find(
                                    (charge) =>
                                        charge.code === field?.element?.code
                                )?.devise
                        ) || []
                    }
                    hiddenOption="Selectionnez la caisse"
                    onChange={handleSelectChange}
                    handleProps={{ index, setFormFields, formFields }}
                    labelStyle={{
                        fontSize: '13px',
                        paddingBottom: '8px',
                        lineHeight: '20px',
                        minHeight: '20px',
                        display: 'block',
                        color: 'rgba(0,0,0,0.5)'
                    }}
                    label="Caisse"
                    selectClassName="form-select form-select-sm col-3"
                    idProperty="id"
                />
            ) : null}
            {/* Prix Input */}
            {charges.find((charge) => charge.code === field.element.code)
                ?.estQuantite ? (
                <CustomLiteDynamicInput
                    name="prix"
                    placeholder="Prix..."
                    containerClassName={
                        getClassNames({
                            moyenPaiement: field?.typeMoyenPaiement,
                            estQuantite: charges.find(
                                (charge) => charge.code === field.element.code
                            )?.estQuantite
                        })?.prixClass
                    }
                    labelStyle={{
                        fontSize: '13px',
                        paddingBottom: '8px',
                        lineHeight: '20px',
                        minHeight: '20px',
                        display: 'block',
                        color: 'rgba(0,0,0,0.5)'
                    }}
                    label="Prix"
                    value={field.prix}
                    onChange={handleChange}
                    inputProps={{ index, setFormFields, formFields }}
                    inputClassName="form-control form-control-sm col-2"
                />
            ) : null}
            <div className="col-auto pt-2">
                <AiFillMinusCircle
                    role="button"
                    title="Supprimer toutes les informations liées à cette charge"
                    className="text-secondary fs-4"
                    onClick={() =>
                        handleRemove({ index, formFields, setFormFields })
                    }
                />
            </div>
        </div>
    );
}
