import { BENEFICIAIRE } from '@napp-inc/jnapp-util';
import React from 'react';
import { AiFillMinusCircle } from 'react-icons/ai';
import { IoIosAddCircleOutline } from 'react-icons/io';
import {
    CustomLiteDynamicDropdown,
    CustomLiteDynamicInput
} from '../../../../../../components';

export function BeneficiaireRowSelector({
    id,
    index,
    detailIndex,
    periodeIndex,
    beneficiaireIndex,
    handleChange,
    handleSelectChange,
    handleRemove,
    handleAddHighLevel,
    beneficiaireItem,
    beneficiaresOptions = [],
    charges = [],
    formFields = [],
    setFormFields
}) {
    return (
        <div
            className="d-flex flex-row align-items-center justify-content-center"
            id={id}
        >
            {/* Type Beneficiares */}
            <CustomLiteDynamicDropdown
                containerClassName="col-2 me-3"
                ariaLabel="Type Beneficiaire Selector"
                name="typeEntite"
                value={beneficiaireItem?.typeEntite}
                options={BENEFICIAIRE.CHARGE_FIXE}
                hiddenOption="Selectionnez Type Bénéficiaire"
                onChange={handleSelectChange}
                handleProps={{
                    index,
                    indexAtSecondLevel: detailIndex,
                    indexAtThirdLevel: periodeIndex,
                    id: beneficiaireIndex,
                    setFormFields,
                    formFields
                }}
                labelStyle={{
                    fontSize: '13px',
                    paddingBottom: '8px',
                    lineHeight: '20px',
                    minHeight: '20px',
                    display: 'block',
                    color: 'rgba(0,0,0,0.5)'
                }}
                label="Type Bénéficiaire"
                selectClassName="form-select form-select-sm col-3"
            />
            {/* Beneficiaire */}
            <CustomLiteDynamicDropdown
                containerClassName="col-2 me-3"
                ariaLabel="Beneficiaire Selector"
                name="valeurTypeEntite.reference|valeurTypeEntite.designation"
                customproperty="beneficiaire"
                value={beneficiaireItem?.valeurTypeEntite?.reference}
                options={beneficiaresOptions}
                hiddenOption="Selectionnez Bénéficiaire"
                onChange={handleSelectChange}
                handleProps={{
                    index,
                    indexAtSecondLevel: detailIndex,
                    indexAtThirdLevel: periodeIndex,
                    id: beneficiaireIndex,
                    setFormFields,
                    formFields
                }}
                labelStyle={{
                    fontSize: '13px',
                    paddingBottom: '8px',
                    lineHeight: '20px',
                    minHeight: '20px',
                    display: 'block',
                    color: 'rgba(0,0,0,0.5)'
                }}
                label="Bénéficiaire"
                selectClassName="form-select form-select-sm col-3"
                idProperty="id"
            />
            {/* Montant ou Quantité */}
            <CustomLiteDynamicInput
                placeholder="Saississez un nombre"
                containerClassName="col-2 me-3"
                labelStyle={{
                    fontSize: '13px',
                    paddingBottom: '8px',
                    lineHeight: '20px',
                    minHeight: '20px',
                    display: 'block',
                    color: 'rgba(0,0,0,0.5)'
                }}
                label={`${
                    charges.find(
                        (charge) =>
                            charge.code === formFields[index].element.code
                    )?.estQuantite
                        ? 'Quantite'
                        : 'Montant'
                }`}
                inputClassName="form-control form-control-sm col-2"
                ariaLabel="Amount and Quantity Input"
                name={`${
                    charges.find(
                        (charge) =>
                            charge.code === formFields[index].element.code
                    )?.estQuantite
                        ? 'quantite'
                        : 'montant'
                }`}
                value={
                    charges.find(
                        (charge) =>
                            charge.code === formFields[index].element.code
                    )?.estQuantite
                        ? beneficiaireItem.quantite
                        : beneficiaireItem.montant
                }
                onChange={handleChange}
                inputProps={{
                    index,
                    indexAtSecondLevel: detailIndex,
                    indexAtThirdLevel: periodeIndex,
                    id: beneficiaireIndex,
                    setFormFields,
                    formFields
                }}
            />
            <div className="col-auto me-3 pt-2">
                <AiFillMinusCircle
                    role="button"
                    title="Supprimer Bénéficiaire"
                    className="text-secondary fs-4"
                    onClick={() =>
                        handleRemove({
                            index,
                            indexAtSecondLevel: detailIndex,
                            indexAtThirdLevel: periodeIndex,
                            id: beneficiaireIndex,
                            formFields,
                            setFormFields
                        })
                    }
                />
            </div>
            <div className="col-auto me-3 pt-2">
                <IoIosAddCircleOutline
                    role="button"
                    title="Ajouter Bénéficiaire"
                    className="text-primary fs-4"
                    onClick={() =>
                        handleAddHighLevel({
                            type: 'Beneficiaires',
                            ancestorId: index,
                            indexAtSecondLevel: detailIndex,
                            indexAtThirdLevel: periodeIndex,
                            formFields,
                            setFormFields
                        })
                    }
                />
            </div>
        </div>
    );
}
